h1 {
  margin: 1rem 0;
}
h2 {
  text-transform: uppercase;
  margin: .8rem;
  word-wrap: none;
}
h3 {
  margin: .8rem;
}
h4 {
  margin: .5rem;
}
h5 {
  margin: .5rem;
}
p {
  margin: .25rem;
}
hr {
  margin-bottom: 0;
}
.bodyTheme {
  background-color: #faf4f4;
}
a {
  text-decoration:none;
}
a:link {
  color: #2A2A2A;
}

a:visited {
  color: #2A2A2A;
}

a:hover {
  color: #101010;
}

button {
  text-decoration:none;
  color: #2A2A2A;
  fill: #2A2A2A;
  cursor: pointer;
  border-radius: .15rem;
  padding: .4rem 1.2rem;
  transition: all 0.2s;
  text-align:center;
  font-weight:500;
  background-color: #FDFDFD;
  margin: .75rem 1.25rem;
  border-width: 0 0 .075rem 0;
  border-color: #2A2A2A;
  box-shadow: RGBA(29, 29, 29, .5) 0 0 .1rem 0;
}

button:hover, button:active {
  background-color: #454545;
  color: #F4F4F5;
  filter: drop-shadow(0 .05rem .05rem RGBA(29, 29, 29, .8));
  fill: #F4F4F5;
}

button:disabled {
  color: #6f6f6f;
  background-color: #aaaaaa;
  cursor: default;
  filter: drop-shadow(0 0);
  border-color: #8c8c8c;
}

input[type="checkbox"] {
  height: 1.5rem;
  width: 1.5rem;
  cursor:pointer;
  -webkit-transition: .10s;
  border-radius:.4rem;
  margin-right: .75rem;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }